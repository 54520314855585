/* =======================================================
Website: Capable Health
URL: https://www.capablehealth.com
Company : Rugged Peak, LLC
Author: Matthew J. Stellato
Author URI: https://www.ruggedpeak.co/
================================================================== */


$blue: #0000C8;

/*  Getting Organized // Base Structure & Styles
---------------------------------------------------------- */

/* body {
	line-height: 24px;
	font-style: normal;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
*/

h1, h2, h3, h4, h5, strong {
  font-weight: 500 !important;
  letter-spacing: -0.015em;
}

.wrap {
	max-width:600px;
	margin: 0 auto;
	padding:30px;
	position: relative;
	box-sizing: border-box;

	&:after {
		visibility: hidden;
		display: block;
		content: "";
		clear: both;
		height: 0;
	}
	
  @media (max-width:600px) {
    padding: 20px;
  }	
}

footer {
  position: fixed;
  bottom:0;
  text-align: center;
  padding: 12px 20px;
  background:#FFF;
  font-size:11px;
  border-top:1px solid #e1e1e1;
  left: 0;
  right: 0;
  color:#000;
}


/*  Recommendations
---------------------------------------------------------- */
.recommendations {
  .type {
    font-size:13px;
    color:#919191;
    display: block;
    padding-bottom:10px;
    text-transform: capitalize;
    line-height: normal;
  }
  
  h1 {
    text-align: center;
    font-size: 26px;
    line-height: normal;
    margin-bottom:30px;
  }
}

.actions {
  display: flex;
  justify-content: center;
 .btn {
    background: $blue;
    color:#FFF;
    text-decoration: none;
    display: inline-block;
    padding:10px 20px;   
    font-weight: 500;
    font-size:16px;
    font-family: 'Rubik', sans-serif;  
    text-transform: uppercase; 
    border-radius: 4px;  
    border: 0; 
    transition: ease 0.3s all;
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      background: #000;
    }
    &.alt {
      background:#AAA;
      &:hover {
        background:#999;
      }
    }  
  } 
}